import React from 'react';
import { StoreProvider } from './src/context/StoreContext';
import { ParallaxProvider } from 'react-scroll-parallax';

export const wrapRootElement = ({ element }) => {
  return <StoreProvider>{element}</StoreProvider>;
};

export const wrapPageElement = ({ element }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>;
};
