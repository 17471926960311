export const IS_DEV_STORE = false; //set true when testing
export const GOOGLE_PLACES_BASE_PATH =
  'https://maps.googleapis.com/maps/api/place/';
export const GOOGLE_PLACES_API_KEY = process.env.GOOGLE_PLACES_API_KEY;
export const SUMUP_PAYMENT_API_KEY = IS_DEV_STORE
  ? `Bearer ${process.env.SUMUP_PAYMENT_API_TOKEN_TEST}`
  : `Bearer ${process.env.SUMUP_PAYMENT_API_TOKEN}`;
export const SUMUP_PAY_TO_EMAIL = IS_DEV_STORE
  ? 'kalle@makersden.io'
  : 'federicogulino@drylaw.eco';
export const SUMUP_PAY_TO_EMAIL_FREE = IS_DEV_STORE
  ? '81254fb5999a4fa595bca1db87b45f3e@developer.sumup.com'
  : 'f5c3cba6b12a4436bf683efc81968b08@developer.sumup.com';
export const SUMUP_MERCHANT_ID = IS_DEV_STORE ? 'MVV2CPDY' : 'MCCVUHDY';
export const IS_SUMUP_FREE_TEST = false; //simulate purchase but not charging card
export const POSTMARK_SENDER_EMAIL = IS_DEV_STORE
  ? 'pawel.pajor@makersden.io'
  : 'hello@drylaw.eco';
export const POSTMARK_API_TOKEN = IS_DEV_STORE
  ? process.env.POSTMARK_API_TOKEN_TEST
  : process.env.POSTMARK_API_TOKEN;
export const SHOPIFY_STORE_DOMAIN = IS_DEV_STORE
  ? process.env.GATSBY_SHOPIFY_STORE_URL_TEST
  : process.env.GATSBY_SHOPIFY_STORE_URL;
export const SHOPIFY_ACCESS_TOKEN = IS_DEV_STORE
  ? process.env.SHOPIFY_ADMIN_TOKEN_TEST
  : process.env.SHOPIFY_ADMIN_TOKEN;
