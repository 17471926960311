exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-debug-js": () => import("./../../../src/pages/debug.js" /* webpackChunkName: "component---src-pages-debug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-cocktail-archive-js": () => import("./../../../src/templates/cocktail-archive.js" /* webpackChunkName: "component---src-templates-cocktail-archive-js" */),
  "component---src-templates-cocktail-recipe-js": () => import("./../../../src/templates/cocktail-recipe.js" /* webpackChunkName: "component---src-templates-cocktail-recipe-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/product-detail-page.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-product-listing-page-js": () => import("./../../../src/templates/product-listing-page.js" /* webpackChunkName: "component---src-templates-product-listing-page-js" */)
}

