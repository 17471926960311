import React, { createContext, useState, useEffect } from 'react';

import Client from 'shopify-buy/index.unoptimized.umd';
import { IS_DEV_STORE } from '../lib/constants';

const createDraftOrder = async body => {
  const response = await fetch('/api/createDraftOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const retrieveDraftOrder = async body => {
  const response = await fetch('/api/retrieveDraftOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const retrieveShippingRates = async body => {
  const response = await fetch('/api/retrieveShippingRates', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const retrieveDiscountCode = async body => {
  const response = await fetch('/api/retrieveDiscountCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const updateDraftOrder = async body => {
  const response = await fetch('/api/updateDraftOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const client = Client.buildClient({
  domain: IS_DEV_STORE
    ? process.env.GATSBY_SHOPIFY_STORE_URL_TEST
    : process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: IS_DEV_STORE
    ? process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_TEST
    : process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

export const checkoutStates = {
  DELIVERY_OPTIONS: 'DELIVERY_OPTIONS',
  DELIVERY_SPEED: 'DELIVERY_SPEED',
  PAYMENT: 'PAYMENT',
};

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},

  isCartOpen: false,
  toggleCartOpen: () => {},

  client,
  checkout: {
    id: 0,
    line_items: [],
    price: 0,
    webUrl: '',
    applied_discount: [],
    customAttributes: [],
    email: '',
    shipping_address: {},
    billing_address: {},
    shipping_rate: '',
  },
  billingAddress: null,
  availableShippingRates: [],
  selectedDelivery: null,

  setSelectedDelivery: () => {},
  addProductToCheckout: () => {},
  updateProductInCheckout: () => {},
  removeProductFromCheckout: () => {},
  addDiscountToCheckout: () => {},
  removeDiscountFromCheckout: () => {},
  updateShippingAddress: () => {},
  updateCheckoutShippingLine: () => {},
  getPossiblyUnavailableProductsIds: () => {},
  changeCheckoutState: () => {},
  resetCheckoutData: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive
  );
  const [isCartOpen, setCartOpen] = useState(defaultValues.isCartOpen);
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [checkoutState, setCheckoutState] = useState(
    checkoutStates.DELIVERY_OPTIONS
  );
  const [availableShippingRates, setAvailableShippingRates] = useState(
    defaultValues.availableShippingRates
  );
  const [selectedDelivery, setSelectedDelivery] = useState(
    defaultValues.selectedDelivery
  );
  const [billingAddress, setBillingAddress] = useState(
    defaultValues.billingAddress
  );

  const isBrowser = typeof window !== 'undefined';
  const localStorageCheckoutIdKey = `${
    IS_DEV_STORE
      ? process.env.GATSBY_SHOPIFY_SHOP_NAME_TEST
      : process.env.GATSBY_SHOPIFY_SHOP_NAME
  }:order-id`;

  useEffect(() => {
    if (typeof window !== `undefined` && checkout.id) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const discountCode = urlParams.get('discount');
      if (discountCode) {
        setCartOpen(true);
        addDiscountToCheckout(discountCode);
      }
    }
  }, [checkout.id]);

  useEffect(() => {
    initialiseCheckout();
  }, []);

  const initialiseCheckout = async () => {
    const checkoutId = isBrowser
      ? localStorage.getItem(localStorageCheckoutIdKey)
      : null;

    console.log('checkout id', checkoutId);
    if (checkoutId) {
      try {
        const fetchedCheckout = await fetchDraftOrder(checkoutId);
        console.log('fetched checkout', fetchedCheckout);
        if (!fetchedCheckout.hasOwnProperty('error')) {
          setCheckout(fetchedCheckout.draft_order);
          //  setSelectedDelivery(fetchedCheckout.draft_order.shipping_line); // causing auto selection of the delivery even if summary says there is no selected
          // removeDiscountFromCheckout(checkoutId); // this doing some issues with shipping line
        }
      } catch (e) {
        console.error(e);
      }
      //if no checkout id, draft order is created when we add product to cart
    }
  };

  const getNewCheckout = async lineItem => {
    const lineItemsObject = {
      line_items: [lineItem],
    };

    try {
      const newCheckout = await createDraftOrder(lineItemsObject);
      if (isBrowser) {
        localStorage.setItem(
          localStorageCheckoutIdKey,
          newCheckout.draft_order.id
        );
      }

      return newCheckout;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDraftOrder = async id => {
    const draftOrderData = await retrieveDraftOrder({ id });

    return draftOrderData;
  };

  const addProductToCheckout = async (productId, quantity = 1) => {
    try {
      const product = await client.product.fetch(productId);

      const splittedFullVariantId = product.variants[0].id.split('/');
      const variantId = Number(
        splittedFullVariantId[splittedFullVariantId.length - 1]
      );

      const fetchedProductObject = {
        variant_id: variantId,
        quantity: Number(quantity),
        properties: [
          {
            name: 'imageSrc',
            value: product.variants[0].image.src,
          },
        ],
      };

      if (checkout.line_items.length === 0) {
        // if there is no line items (checkout default values), create a new draft order
        const newCheckout = await getNewCheckout(fetchedProductObject);
        setCheckout(newCheckout.draft_order);
        return newCheckout.draft_order; // Ensure you return the result here
      } else {
        // if draft order exists, update line items
        const ifItemExist = checkout?.line_items
          ? checkout.line_items.find(item => item.variant_id === variantId)
          : null;

        const updatedLineItems = checkout?.line_items.map(item => {
          if (item.variant_id === variantId)
            item.quantity = Number(item.quantity) + Number(quantity);
          return item;
        });

        let lineItemsToUpdate = [...updatedLineItems];

        if (!ifItemExist) {
          lineItemsToUpdate.push(fetchedProductObject);
        }

        const productUpdateObject = {
          id: checkout.id,
          line_items: lineItemsToUpdate,
        };

        const updatedCheckout = await updateDraftOrder(productUpdateObject);
        if (!updatedCheckout.hasOwnProperty('error')) {
          setCheckout(updatedCheckout.draft_order);
          return updatedCheckout.draft_order;
        }
      }
    } catch (error) {
      console.error('Error adding product to checkout:', error);
      return undefined;
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    // Update the checkout line items based on lineItemId

    const updatedLineItems = checkout?.line_items.map(item => {
      if (item.id === lineItemId) item.quantity = Number(quantity);
      return item;
    });

    const lineItemsUpdateObject = {
      id: checkout.id,
      line_items: updatedLineItems,
    };

    const updatedCheckout = await updateDraftOrder(lineItemsUpdateObject);
    if (!updatedCheckout.hasOwnProperty('error'))
      setCheckout(updatedCheckout.draft_order);
  };

  const getPossiblyUnavailableProductsIds = async lineItems => {
    try {
      const unavailableProducts = await lineItems.reduce(
        async (accPromise, item) => {
          const acc = await accPromise;
          const { quantity, product_id: productId } = item;
          const shopifyProductId = `gid://shopify/Product/${productId}`;
          const product = await getProductById(shopifyProductId);
          if (product.hasOwnProperty('errors')) {
            throw new Error('Error fetching product details', product.errors);
          }

          if (
            product.hasOwnProperty('data') &&
            (product.data.product.availableForSale === false ||
              product.data.product.totalInventory < quantity)
          ) {
            acc.push(productId);
          }

          return acc;
        },
        Promise.resolve([])
      );

      return unavailableProducts;
    } catch (error) {
      console.error('Error processing line items:', error);
      throw error;
    }
  };

  const getProductById = async productId => {
    try {
      const getProductByIdQuery = graphQLClient => {
        return graphQLClient.query(root => {
          root.add('product', { args: { id: productId } }, product => {
            product.add('title');
            product.add('totalInventory');
            product.add('availableForSale');
          });
        });
      };

      const response = await client.graphQLClient.send(getProductByIdQuery);

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const updateShippingAddress = async addressData => {
    const {
      addressLine1,
      addressLine2,
      firstName,
      lastName,
      postcode,
      town,
      email,
      phoneNumber,
      billingAddressLine1,
      billingAddressLine2,
      billingFirstName,
      billingLastName,
      billingPostcode,
      billingTown,
      billingPhoneNumber,
      billingEmail,
      sameAsDelivery,
    } = addressData;

    const updateDraftOrderBody = {
      id: checkout.id,
      shipping_address: {
        address1: addressLine1,
        address2: addressLine2,
        city: town,
        company: '',
        country: 'GB',
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        province: town,
        zip: postcode,
      },
      billing_address: {
        address1: sameAsDelivery ? addressLine1 : billingAddressLine1,
        address2: sameAsDelivery ? addressLine2 : billingAddressLine2,
        city: sameAsDelivery ? town : billingTown,
        company: '',
        country: 'GB',
        first_name: sameAsDelivery ? firstName : billingFirstName,
        last_name: sameAsDelivery ? lastName : billingLastName,
        phone: sameAsDelivery ? phoneNumber : billingPhoneNumber,
        province: sameAsDelivery ? town : billingTown,
        zip: sameAsDelivery ? postcode : billingPostcode,
      },
      email,
    };

    const updatedCheckout = await updateDraftOrder(updateDraftOrderBody);
    if (!updatedCheckout.hasOwnProperty('error')) {
      const shippingRates = await fetchShippingRates(
        updatedCheckout.draft_order
      );

      setAvailableShippingRates(shippingRates);
      setCheckout(updatedCheckout.draft_order);
      return updatedCheckout;
    } else {
      return null;
    }
  };

  const fetchShippingRates = async latestCheckout => {
    //graphql layer has different data structure, that's why this need to be transformed
    const lineItemsMutationInput = latestCheckout.line_items.map(item => ({
      variantId: `gid://shopify/ProductVariant/${item.variant_id}`,
      quantity: item.quantity,
    }));

    const mutationInput = {
      lineItems: lineItemsMutationInput,
      shippingAddress: {
        firstName: latestCheckout.shipping_address.first_name,
        lastName: latestCheckout.shipping_address.last_name,
        address1: latestCheckout.shipping_address.address1,
        address2: latestCheckout.shipping_address.address2,
        phone: '',
        city: latestCheckout.shipping_address.city,
        countryCode: 'GB',
        zip: latestCheckout.shipping_address.zip,
      },
    };

    const shippingRatesResponse = await retrieveShippingRates(mutationInput);
    if (!shippingRatesResponse.hasOwnProperty('error')) {
      return shippingRatesResponse.data.draftOrderCalculate.calculatedDraftOrder
        .availableShippingRates;
    } else {
      return null;
    }
  };

  const updateCheckoutShippingLine = async shippingRate => {
    const handleToAdd = {
      id: checkout.id,
      shipping_line: {
        handle: shippingRate.handle,
        title: shippingRate.title,
        price: shippingRate.price.amount,
      },
    };
    const updatedDraftOrder = await updateDraftOrder(handleToAdd);
    if (!updatedDraftOrder.hasOwnProperty('error')) {
      setCheckout(updatedDraftOrder.draft_order);
      setSelectedDelivery(updatedDraftOrder.draft_order.shipping_line);

      return updatedDraftOrder.draft_order;
    } else {
      return null;
    }
  };

  const removeProductFromCheckout = async lineItemId => {
    // Filter out the line item that matches the lineItemId
    const updatedLineItems = checkout?.line_items.filter(
      item => item.id !== lineItemId
    );

    if (updatedLineItems.length === 0) {
      //draft order need to have at least one product in line items, so if we delete last product in cart we should delete draft order
      //with this approach we reset all the data for existing checkout but the draft order in shopify exist
      //after adding new line item to cart new draft order will be created
      //todo: delete draft order from shopify as well (Maybe not neccessary?)
      resetCheckoutData();
    } else {
      const lineItemsUpdateObject = {
        id: checkout.id,
        line_items: updatedLineItems,
      };

      const updatedCheckout = await updateDraftOrder(lineItemsUpdateObject);
      if (!updatedCheckout.hasOwnProperty('error'))
        setCheckout(updatedCheckout.draft_order);
    }
  };

  const addDiscountToCheckout = async discountCode => {
    const discountCodeData = await retrieveDiscountCode({ discountCode });
    if (discountCodeData.error) {
      return null;
    } else {
      const discountValue = Math.abs(Number(discountCodeData.price_rule.value));
      const discountToAdd = {
        id: checkout.id,
        applied_discount: {
          description: discountCodeData.price_rule.title,
          value_type: discountCodeData.price_rule.value_type,
          value: `${discountValue}`,
          title: discountCodeData.price_rule.title,
        },
      };

      const updatedCheckout = await updateDraftOrder(discountToAdd);
      if (!updatedCheckout.hasOwnProperty('error'))
        setCheckout(updatedCheckout.draft_order);
    }
  };

  const removeDiscountFromCheckout = async checkoutId => {
    const deleteDiscountObject = {
      id: checkoutId,
      applied_discount: null,
    };
    const updatedCheckout = await updateDraftOrder(deleteDiscountObject);
    if (!updatedCheckout.hasOwnProperty('error'))
      setCheckout(updatedCheckout.draft_order);
  };

  const changeCheckoutState = checkoutState => {
    setCheckoutState(checkoutState);
  };

  const toggleCartOpen = () => {
    setCartOpen(!isCartOpen);
  };

  const resetCheckoutData = () => {
    setCheckout(defaultValues.checkout);
    setCheckoutState(checkoutStates.DELIVERY_OPTIONS);
    setAvailableShippingRates(defaultValues.availableShippingRates);
    setSelectedDelivery(defaultValues.selectedDelivery);
    setBillingAddress(defaultValues.billingAddress);
    localStorage.removeItem(localStorageCheckoutIdKey);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,

        isCartOpen,
        toggleCartOpen,

        client,
        checkout,
        checkoutState,
        billingAddress,
        availableShippingRates,
        selectedDelivery,
        setSelectedDelivery,
        addProductToCheckout,
        updateProductInCheckout,
        removeProductFromCheckout,
        addDiscountToCheckout,
        removeDiscountFromCheckout,
        updateShippingAddress,
        updateCheckoutShippingLine,
        getPossiblyUnavailableProductsIds,
        changeCheckoutState,
        resetCheckoutData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
